import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
// import {
//   GetReEmailRes,
//   setReEmailRes,
// } from "../../../StateManagement/Reducers/PreLoginState";

import LoginLeft from "../LoginLeft";

import checkEmail from "../../../Assets/images/email_check_icon.png";

import {
  Button,
  Span,
  Form,
  FormGroup,
  FormLabel,
  Image,
  Div,
  Description,
} from "../../StyledComponents";
import {
  Container,
  Input,
  TitleImage,
  Title,
  ImageContainer,
  ResendButton,
} from "../StyledComponents";
import { FormGroupInline } from "./Styles";
import {
  GetEmailRes,
  setEmailRes,
} from "../../../StateManagement/Reducers/PreLoginState";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";

const InstantLink = () => {
  const { state } = useLocation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [btndis, setbtndis] = useState(false);
  const [email] = useState(state ? state.type.email : "");
  const [otpErr, setOtpErr] = useState(false);
  const [Otp, setOtp] = useState(new Array(4).fill(""));
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalStatus, setModalStatus] = useState("");

  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }

  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";

  const otp =
    localStorage.getItem("OTP") &&
    Decrypt_Value(localStorage.getItem("OTP"), "vozoportal");

  let mode =
    localStorage.getItem("Mode") &&
    Decrypt_Value(localStorage.getItem("Mode"), "vozoportal");
  const text = useSelector((state) => state.PreLogin.logo);

  const handleVerify = (e) => {
    setbtndis(true);
    setTimeout(() => {
      setbtndis(false);
    }, 1000);
    e.preventDefault();

    let isEmptyObj = Object.keys(mode).length;
    if (isEmptyObj === 0) {
      mode = "old";
    }

    if (parseInt(otp) === parseInt(Otp.join(""))) {
      if (mode === "old") {
        Navigate("/SetupPassword", { state: { type: { email } } });
      } else if (mode === "new") {
        Navigate("/SignUp", { state: { type: { email } } });
      }
      localStorage.removeItem("OTP");
    } else {
      setOtpErr(true);
      // localStorage.removeItem("OTP");
    }
  };

  const handleOtp = (e, i) => {
    setOtpErr(false);
    if (isNaN(e.value)) return;
    setOtp([...Otp.map((r, ind) => (ind === i ? e.value : r))]);

    if (e.parentElement.nextSibling) {
      e.parentElement.nextSibling.firstChild.focus();
    }
  };

  const KeyDown = (e) => {
    if (e.keyCode === 8) {
      if (e.target.parentElement.previousSibling) {
        e.target.parentElement.previousSibling.firstChild.focus();
      }
    }
  };

  const FieldPaste = (evt, i) => {
    const pasteVal = evt.clipboardData.getData("Text").toString();
    setOtpErr(false);
    if (isNaN(pasteVal)) return;
    setOtp([...Otp.map((r, ind) => pasteVal.at(ind))]);
  };

  const ResendOtp = () => {
    dispatch(GetEmailRes({ type: { email: email }, mode: mode }));
    setModalMessage("Verification code has been resent to your email.");
    setModalStatus("success");
    setShowModal(true);
    setTimeout(() => {
      setShowModal(false);
    }, 3000);
  };

  useEffect(() => {
    dispatch(setEmailRes({ status: "", output: "" }));
  }, []);

  return (
    <Container Flex height="100vh" minWidth="1024px">
      <Div width="50%" bg_color="#F8FAFB" position="relative">
        <LoginLeft />
      </Div>
      <Div width="50%" bg_color="#FFFFFF">
        <Div width="100%">
          <Div width="500px" textAlign="center" margin="auto">
            {text.length !== 0 && (
              <ImageContainer marginTop="72px" marginBottom="65px">
                <Image
                  loading="lazy"
                  src={File_URL + text}
                  height="41px"
                  width="173px"
                />
              </ImageContainer>
            )}
            <TitleImage
              src={checkEmail}
              alt="Email Verified"
              width="124px"
              height="124px"
              marginBottom="35px"
              marginTop="10%"
            ></TitleImage>
            <Title
              color="#000000"
              fontWeight="800"
              fontSize="36px"
              lineHeight="44px"
              marginBottom="18px"
            >
              Verify your Account
            </Title>
            <Description
              color=" #667085"
              alignItems="center"
              fontSize="20px"
              lineHeight="30px"
              marginBottom="40px"
            >
              Check you email, we just sent you a verification code to &nbsp;
              <Span color="#1F28EB" fontWeight="600">
                {email}
              </Span>
            </Description>
            <Form>
              <FormGroupInline>
                {Otp.map((row, i) => {
                  return (
                    <FormGroup
                      marginBottom="24px"
                      display="inline-grid"
                      width="25%"
                      key={i}
                    >
                      <Input
                        key={i}
                        width="80px"
                        height="80px"
                        fontSize="36px"
                        lineHeight="44px"
                        fontWeight="700"
                        fontFamily="'Urbanist'"
                        maxLength="1"
                        textAlign="center"
                        maxlength="1"
                        value={row}
                        onChange={(e) => handleOtp(e.target, i)}
                        onFocus={(e) => e.target.select()}
                        onKeyUp={(e) => KeyDown(e, i)}
                        onPaste={(e) => FieldPaste(e, i)}
                      />
                    </FormGroup>
                  );
                })}
                {otpErr && (
                  <FormGroup
                    marginBottom="24px"
                    display="inline-grid"
                    width="100%"
                    gridColumn="1/5"
                  >
                    <FormLabel
                      marginBottom="6px"
                      textAlign="center"
                      fontSize="14px"
                      color="red"
                      fontWeight="400"
                      lineHeight="24px"
                      fontFamily="inter"
                    >
                      otp is inValid
                    </FormLabel>
                  </FormGroup>
                )}
              </FormGroupInline>
              <FormGroup marginBottom="32px" display="grid" width="100%">
                <Button
                  background="#2C7BE5"
                  hoverBackColor="#005FB2"
                  className="save_active"
                  padding="18px 140px 18px 140px"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="22px"
                  letterSpacing="1.1px"
                  disabled={btndis}
                  onClick={(e) => handleVerify(e)}
                >
                  Verify Account
                </Button>
              </FormGroup>
            </Form>
            <FormGroup
              textAlign="center"
              marginBottom="63px"
              display="inline-block"
            >
              Don’t receive verification code?
              <ResendButton onClick={() => ResendOtp()}>
                Resend Code
              </ResendButton>
            </FormGroup>
          </Div>
        </Div>
      </Div>
      <AlertModal show={showModal} status={modalStatus} msg={modalMessage} />
    </Container>
  );
};

export default InstantLink;
