import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetWelcome } from "../../../StateManagement/Reducers/DashboardState";
import SignUp from "../../../Assets/images/SignUp.png";
import {
  Modal,
  ModalContainer,
  Div,
  Image,
  Button,
  Text,
} from "../../StyledComponents";
import { ModalDialog } from "../../StyledComponents/AddCard/Styles";

const Welcome = (props) => {
  const dispatch = useDispatch();
  const PatName = useSelector((state) => state.TopNavbar.userDetails.patname);
  const [ModalAnimat] = useState(true);
  const Welcomepopup = useSelector((state) => state.Dashboard.Welcome);

  useEffect(() => {
    dispatch(GetWelcome());
  }, []);
  useEffect(() => {
    if (props.show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [props.show]);
  return (
    <Modal width="100%" height="100%" show={props.show}>
      <ModalContainer justifyContent="center">
        <ModalDialog animation={ModalAnimat} width="455px">
          <Div
            height="479px"
            width="455px"
            borderRadius="8px"
            textAlign="center"
            padding="20px 20px 20px 20px"
          >
            <Image src={SignUp} height="185px" width="200px" />
            <Text
              fontSize="32px"
              fontWeight="600"
              color="#2C7BE5"
              fontFamily="Inter"
              lineHeight="unset"
              fontStyle="Italic"
              mt="16px"
            >
              Welcome
            </Text>
            <Text
              fontSize="16px"
              fontWeight="500"
              color="#2D3748"
              fontFamily="Inter"
              textAlign="left"
              mt="8px"
            >
              {PatName},
            </Text>
            <Text
              fontSize="14px"
              fontWeight="400"
              color="#2D3748"
              fontFamily="Inter"
              textAlign="left"
              lineHeight="17px"
              mt="9px"
            >
              {Welcomepopup}
            </Text>
            <Button
              background="#407BFF"
              borderRadius="3px"
              padding="8px 38px 8px 38px"
              fontSize="14px"
              fontWeight="400"
              lineHeight="16px"
              fontFamily="Inter"
              marginTop="24px"
              marginBottom="0px"
              onClick={props.ModalHide}
            >
              Continue
            </Button>
          </Div>
        </ModalDialog>
      </ModalContainer>
    </Modal>
  );
};

export default Welcome;
