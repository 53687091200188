import React, { useState, useEffect, useRef, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import EmptyData from "../../StyledComponents/EmptyData";
import { CommonDiv } from "../../StyledComponents";
import Notification_delete from "../../../Assets/images/Notification_delete.png";
import Notification_deleteAccept from "../../../Assets/images/Notification_deleteAccept.png";
import notification_deleteCancel from "../../../Assets/images/notification_deleteCancel.png";
import NotificationDeleteAll from "../../../Assets/images/NotificationDeleteAll.png";
import { Header, Title } from "../Styles";
import { changeAppointmentData } from "../../../StateManagement/Reducers/AppointmentsState";
import { getDocumentslist } from "../../../StateManagement/Reducers/DocumentsState";
import {
  GetNotificationDelete,
  GetNotificationlab,
} from "../../../StateManagement/Reducers/TopNavbarState";

import {
  Data,
  Row,
  Avatar,
  AvatarLetter,
  Image,
  Text,
  Checkbox,
} from "./styles";
const Notification = (props) => {
  // ------------------------------ State and Variables Start--------------------------------------------------- //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [DeleteShowHide, setDeleteShowHide] = useState(true);
  const [NotificationFullData, setNotificationFullData] = useState("");
  let NotificationsData =
    props.NotificationFullData === null ||
    props.NotificationFullData === undefined
      ? []
      : props.NotificationFullData;
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }

  const FileURL = backurl + "/sites/" + DBhost + "/documentimg/";

  const remainingTime = (time) => {
    var apptTime = moment(time);
    var currentTimeFor = moment();
    var duration = moment.duration(apptTime.diff(currentTimeFor));
    var hours = duration.hours();
    var minutes = duration.minutes();

    if (hours > 0) {
      var hrsorhr = hours > 1 ? "Hrs " : "Hr ";
      return " (" + hours + hrsorhr + minutes + "Mins Remaining)";
    } else {
      return " (" + minutes + "Mins Remaining)";
    }
  };

  const NotificationDelete = () => {
    setDeleteShowHide(false);
  };
  const Notification_DeleteAll = () => {
    let tempData = NotificationFullData.map((pat) => {
      return { ...pat, isChecked: true };
    });
    setNotificationFullData(tempData);
  };
  const notificationDeleteCancel = () => {
    setDeleteShowHide(true);
    let tempData = NotificationFullData.map((pat) => {
      return { ...pat, isChecked: false };
    });
    setNotificationFullData(tempData);
  };
  const NotificationDeleteAccept = () => {
    setDeleteShowHide(true);
    props.closepopup();

    let patselectAllVar = NotificationFullData.filter(
      (pat) => pat.isChecked === true
    );

    dispatch(GetNotificationDelete(patselectAllVar));
  };

  const clickCheckbox = (e) => {
    const { name, checked } = e.target;

    let tempData = NotificationFullData.map((pat) =>
      pat.id.toString() === name ? { ...pat, isChecked: checked } : pat
    );

    setNotificationFullData(tempData);
  };

  const reschedule = (id) => {
    let ApptCancelList = NotificationFullData.filter(
      (pat) => pat.names === "app_delete"
    );

    const appointment = ApptCancelList.find((row) => row.id === id);
    if (appointment)
      dispatch(
        changeAppointmentData({
          name: "select_office",
          value: {
            facility_address: appointment.fac_address,
            id: appointment.fac_id,
            name: appointment.fac_name,
            telehealth: "1",
          },
        })
      );
    dispatch(
      changeAppointmentData({
        name: "select_provider",
        value: {
          designation: null,
          id: appointment.prov_id,
          img_url: appointment.img_url,
          name: appointment.uname,
        },
      })
    );
    navigate("/appointment/add_appointment");
    props.closepopup();
  };
  const documentlaborder = (id) => {
    navigate("/documents");
    dispatch(GetNotificationlab("Lab Results"));
    props.closepopup();
  };
  const healthprofilelaborder = (id) => {
    navigate("/HealthProfile", {
      state: { type: { type: "lab" } },
    });
    window.location.reload();
    props.closepopup();
  };

  useEffect(() => {
    let NotificationsData =
      props.NotificationFullData === null ||
      props.NotificationFullData === undefined
        ? []
        : props.NotificationFullData;
    setNotificationFullData(NotificationsData);
  }, [NotificationsData]);
  useEffect(() => {
    setDeleteShowHide(true);
  }, [dispatch, props]);
  // ------------------------------ useEffect End------------------------------------------------------------- //

  return (
    <>
      <Header display="flex" juscon="space-between">
        <Title> Notifications</Title>
        {DeleteShowHide ? (
          <Image
            src={Notification_delete}
            alt="image"
            height="24px"
            width="24px"
            cursor="pointer"
            display={NotificationFullData.length > 0 ? "block" : "none"}
            onClick={() => NotificationDelete()}
          />
        ) : (
          <Row dis="flex">
            <Image
              src={NotificationDeleteAll}
              alt="image"
              height="24px"
              mr="16px"
              width="24px"
              cursor="pointer"
              onClick={() => Notification_DeleteAll()}
            />
            <Image
              src={notification_deleteCancel}
              alt="image"
              mr="16px"
              height="24px"
              cursor="pointer"
              width="24px"
              onClick={() => notificationDeleteCancel()}
            />
            {NotificationFullData.filter((pat) => pat.isChecked === true)
              .length > 0 && (
              <Image
                src={Notification_deleteAccept}
                alt="image"
                mt="4px"
                height="16px"
                cursor="pointer"
                width="20px"
                onClick={() => NotificationDeleteAccept()}
              />
            )}
          </Row>
        )}
      </Header>
      <Data height="285px" width="100%">
        {NotificationFullData.length > 0 ? (
          <>
            {NotificationFullData.map((row, ind) => (
              <>
                {/* Appointment Reminder Array Start */}

                {row.names === "App_reminder" && (
                  <Row
                    key={ind}
                    borderBottom="1px rgba(46, 46, 46, 0.25) solid"
                    dis="flex"
                    height="77px"
                    padding="12px 0px 12px 12px"
                    cursor="pointer"
                  >
                    {DeleteShowHide === false && (
                      <Checkbox
                        type="checkbox"
                        name={row.id}
                        onClick={(e) => clickCheckbox(e)}
                        checked={row?.isChecked || false}
                      ></Checkbox>
                    )}

                    {row.img_url === null || row.img_url === undefined ? (
                      <Avatar
                        flex
                        width="40px"
                        height="40px"
                        border="1px solid #ffffff"
                      >
                        <AvatarLetter>
                          {row.uname?.charAt(0).toUpperCase()}
                        </AvatarLetter>
                      </Avatar>
                    ) : (
                      <Image
                        src={FileURL + row.img_url}
                        alt="image"
                        borderRadius="40px"
                        height="40px"
                        width="40px"
                        mr="12px"
                      />
                    )}
                    <Row
                      flexDirection="column"
                      width="87%"
                      padding="0px 0px 0px 0px"
                    >
                      <Text
                        fontSize="14px"
                        color="#2E2C34"
                        fontFamily="Urbanist"
                        fontWeight="600"
                        lineHeight="17px"
                      >
                        Appointment Reminder
                      </Text>
                      <Text
                        mt="4px"
                        fontFamily="Urbanist"
                        fontSize="12px"
                        color="#8B8B8B"
                        lineHeight="14px"
                      >
                        {row.uname} , {row.fac_name}
                      </Text>
                      <Row
                        dis="flex"
                        justifyContent="space-between"
                        width="100%"
                        marginTop="4px"
                      >
                        <Text
                          fontFamily="Urbanist"
                          fontSize="12px"
                          color="#8B8B8B"
                          lineHeight="14px"
                        >
                          {moment(row.currentTime).format("DD-MM-YYYY hh:mm A")}
                        </Text>

                        <Text
                          fontFamily="Urbanist"
                          fontSize="12px"
                          color="#8B8B8B"
                          lineHeight="14px"
                          ml="8px"
                        >
                          {remainingTime(row.currentTime)}
                        </Text>
                      </Row>
                    </Row>
                  </Row>
                )}

                {/* Appointment Reminder Array End */}

                {/* Consent Form Provider Create Array Start */}

                {row.names === "Consent_prov" && (
                  <Row
                    key={ind}
                    borderBottom="1px rgba(46, 46, 46, 0.25) solid"
                    dis="flex"
                    height={row.req_pat_sign === 1 ? "77px" : "91px"}
                    padding="12px 0px 12px 12px"
                    cursor="pointer"
                  >
                    {DeleteShowHide === false && (
                      <Checkbox
                        type="checkbox"
                        name={row.id}
                        onClick={(e) => clickCheckbox(e)}
                        checked={row?.isChecked || false}
                      ></Checkbox>
                    )}
                    {row.img_url === null || row.img_url === undefined ? (
                      <Avatar
                        flex
                        width="40px"
                        height="40px"
                        border="1px solid #ffffff"
                      >
                        <AvatarLetter>
                          {row.uname?.charAt(0).toUpperCase()}
                        </AvatarLetter>
                      </Avatar>
                    ) : (
                      <Image
                        src={FileURL + row.img_url}
                        alt="image"
                        borderRadius="40px"
                        height="40px"
                        width="40px"
                        mr="12px"
                      />
                    )}
                    <Row
                      flexDirection="column"
                      width="87%"
                      padding="0px 0px 0px 0px"
                    >
                      <Text
                        fontSize="14px"
                        color="#2E2C34"
                        fontFamily="Urbanist"
                        fontWeight="600"
                        lineHeight="17px"
                      >
                        {row.req_pat_sign === 1
                          ? "Consent Form - Signature Needed"
                          : "Consent Form"}
                      </Text>
                      <Text
                        mt="4px"
                        fontFamily="Urbanist"
                        fontSize="12px"
                        color="#8B8B8B"
                        lineHeight="14px"
                      >
                        {row.req_pat_sign === 1
                          ? "Verify and sign the following consent form"
                          : "Read and know about the following consent form"}
                      </Text>
                      <Text
                        mt="4px"
                        color="#2C7BE5"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        width="200px"
                        fontSize="12px"
                        onClick={() => {
                          navigate("/documents", {
                            state: { type: { type: "medication" } },
                          });
                          window.location.reload();
                        }}
                      >
                        {row.form_name}
                      </Text>
                    </Row>
                  </Row>
                )}
                {/* Consent Form Provider Create Array End */}

                {/* Consent Form Provider Accept Array Start */}

                {row.names === "Consent_accept" && (
                  <Row
                    key={ind}
                    borderBottom="1px rgba(46, 46, 46, 0.25) solid"
                    dis="flex"
                    height="91px"
                    padding="12px 12px 12px 12px"
                    cursor="pointer"
                  >
                    {DeleteShowHide === false && (
                      <Checkbox
                        name={row.id}
                        type="checkbox"
                        onClick={(e) => clickCheckbox(e)}
                        checked={row?.isChecked || false}
                      ></Checkbox>
                    )}
                    {row.img_url === null || row.img_url === undefined ? (
                      <Avatar
                        flex
                        width="40px"
                        height="40px"
                        border="1px solid #ffffff"
                      >
                        <AvatarLetter>
                          {row.uname?.charAt(0).toUpperCase()}
                        </AvatarLetter>
                      </Avatar>
                    ) : (
                      <Image
                        src={FileURL + row.img_url}
                        alt="image"
                        borderRadius="40px"
                        height="40px"
                        width="40px"
                        mr="12px"
                      />
                    )}
                    <Row
                      flexDirection="column"
                      width="87%"
                      padding="0px 0px 0px 0px"
                    >
                      <Text
                        fontSize="14px"
                        color="#2E2C34"
                        fontFamily="Urbanist"
                        fontWeight="600"
                        lineHeight="17px"
                      >
                        {row.Portal_check_consent === "1"
                          ? "Consent Form - Reviewed"
                          : "Document Reviewed"}
                      </Text>
                      <Text
                        mt="4px"
                        fontFamily="Urbanist"
                        fontSize="12px"
                        color="#8B8B8B"
                        lineHeight="14px"
                      >
                        {row.Portal_check_consent === "1"
                          ? "Consent form which was signed, reviewed & added to documents in EHR"
                          : "Document which was sent to review, reviewed & added to documents in EHR"}
                      </Text>
                      <Text
                        mt="4px"
                        color="#2C7BE5"
                        fontSize="12px"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        width="200px"
                        onClick={() => {
                          navigate("/documents", {
                            state: { type: { type: "medication" } },
                          });
                          window.location.reload();
                        }}
                      >
                        {row.Portal_check_consent === "1"
                          ? row.form_name
                          : row.doc_name.slice(0, -4)}
                      </Text>
                    </Row>
                  </Row>
                )}
                {/* Consent Form Provider Accept Array End */}

                {/* Problems Array Start */}

                {row.names === "Problems" && (
                  <Row
                    key={ind}
                    borderBottom="1px rgba(46, 46, 46, 0.25) solid"
                    dis="flex"
                    height="64px"
                    padding="12px 0px 12px 12px"
                    cursor="pointer"
                  >
                    {DeleteShowHide === false && (
                      <Checkbox
                        type="checkbox"
                        name={row.id}
                        onClick={(e) => clickCheckbox(e)}
                        checked={row?.isChecked || false}
                      ></Checkbox>
                    )}
                    {row.img_url === null || row.img_url === undefined ? (
                      <Avatar
                        flex
                        width="40px"
                        height="40px"
                        border="1px solid #ffffff"
                      >
                        <AvatarLetter>
                          {row.uname?.charAt(0).toUpperCase()}
                        </AvatarLetter>
                      </Avatar>
                    ) : (
                      <Image
                        src={FileURL + row.img_url}
                        alt="image"
                        borderRadius="40px"
                        height="40px"
                        width="40px"
                        mr="12px"
                      />
                    )}

                    <Row
                      flexDirection="column"
                      width="87%"
                      padding="0px 0px 0px 0px"
                      onClick={() => {
                        navigate("/HealthProfile", {
                          state: { type: { type: "medical_problem" } },
                        });
                        window.location.reload();
                      }}
                    >
                      <Text
                        fontSize="14px"
                        color="#2E2C34"
                        fontFamily="Urbanist"
                        fontWeight="600"
                        lineHeight="17px"
                      >
                        Problem Added
                      </Text>

                      <Text
                        fontFamily="Urbanist"
                        fontSize="12px"
                        color="#8B8B8B"
                        lineHeight="14px"
                        mt="8px"
                      >
                        Click here to view the problem
                      </Text>
                    </Row>
                  </Row>
                )}

                {/* Problems Array End */}

                {/* Medication Array Start */}

                {row.names === "Medication" && (
                  <Row
                    key={ind}
                    borderBottom="1px rgba(46, 46, 46, 0.25) solid"
                    dis="flex"
                    height="64px"
                    padding="12px 0px 12px 12px"
                    cursor="pointer"
                  >
                    {DeleteShowHide === false && (
                      <Checkbox
                        type="checkbox"
                        name={row.id}
                        onClick={(e) => clickCheckbox(e)}
                        checked={row?.isChecked || false}
                      ></Checkbox>
                    )}
                    {row.img_url === null || row.img_url === undefined ? (
                      <Avatar
                        flex
                        width="40px"
                        height="40px"
                        border="1px solid #ffffff"
                      >
                        <AvatarLetter>
                          {row.uname?.charAt(0).toUpperCase()}
                        </AvatarLetter>
                      </Avatar>
                    ) : (
                      <Image
                        src={FileURL + row.img_url}
                        alt="image"
                        borderRadius="40px"
                        height="40px"
                        width="40px"
                        mr="12px"
                      />
                    )}
                    <Row
                      flexDirection="column"
                      width="87%"
                      padding="0px 0px 0px 0px"
                      onClick={() => {
                        navigate("/HealthProfile", {
                          state: { type: { type: "medication" } },
                        });
                        window.location.reload();
                      }}
                    >
                      <Text
                        fontSize="14px"
                        color="#2E2C34"
                        fontFamily="Urbanist"
                        fontWeight="600"
                        lineHeight="17px"
                      >
                        Medication Added
                      </Text>

                      <Text
                        fontFamily="Urbanist"
                        fontSize="12px"
                        color="#8B8B8B"
                        lineHeight="14px"
                        mt="8px"
                      >
                        Click here to view the medication
                      </Text>
                    </Row>
                  </Row>
                )}

                {/* Medication Array End */}

                {/* App Accept  Array Satrt */}
                {row.names === "app_accept" && (
                  <Row
                    key={ind}
                    borderBottom="1px rgba(46, 46, 46, 0.25) solid"
                    dis="flex"
                    height="77px"
                    padding="12px 0px 12px 12px"
                    cursor="pointer"
                  >
                    {DeleteShowHide === false && (
                      <Checkbox
                        type="checkbox"
                        name={row.id}
                        onClick={(e) => clickCheckbox(e)}
                        checked={row?.isChecked || false}
                      ></Checkbox>
                    )}
                    {row.img_url === null || row.img_url === undefined ? (
                      <Avatar
                        flex
                        width="40px"
                        height="40px"
                        border="1px solid #ffffff"
                      >
                        <AvatarLetter>
                          {row.uname?.charAt(0).toUpperCase()}
                        </AvatarLetter>
                      </Avatar>
                    ) : (
                      <Image
                        src={FileURL + row.img_url}
                        alt="image"
                        borderRadius="40px"
                        height="40px"
                        width="40px"
                        mr="12px"
                      />
                    )}
                    <Row
                      flexDirection="column"
                      width="87%"
                      padding="0px 0px 0px 0px"
                    >
                      <Text
                        fontSize="14px"
                        color="#2E2C34"
                        fontFamily="Urbanist"
                        fontWeight="600"
                        lineHeight="17px"
                      >
                        Appointment Confirmed
                      </Text>
                      <Text
                        mt="4px"
                        fontFamily="Urbanist"
                        fontSize="12px"
                        color="rgba(46, 46, 46, 0.50)"
                        fontWeight="400"
                        lineHeight="14px"
                      >
                        {row.uname} , {row.fac_name}
                      </Text>
                      <Row
                        dis="flex"
                        justifyContent="space-between"
                        width="100%"
                        marginTop="4px"
                      >
                        <Text
                          fontFamily="Urbanist"
                          fontSize="12px"
                          color="rgba(46, 46, 46, 0.50)"
                          fontWeight="400"
                          lineHeight="14px"
                        >
                          {moment(row.currentTime).format("DD-MM-YYYY hh:mm A")}
                        </Text>
                      </Row>
                    </Row>
                  </Row>
                )}

                {/* App Accept Array End */}

                {/* App Decline  Array start */}
                {row.names === "app_decline" && (
                  <Row
                    key={ind}
                    borderBottom="1px rgba(46, 46, 46, 0.25) solid"
                    dis="flex"
                    height="143px"
                    padding="12px 0px 12px 12px"
                    cursor="pointer"
                  >
                    {DeleteShowHide === false && (
                      <Checkbox
                        type="checkbox"
                        name={row.id}
                        onClick={(e) => clickCheckbox(e)}
                        checked={row?.isChecked || false}
                      ></Checkbox>
                    )}
                    {row.img_url === null || row.img_url === undefined ? (
                      <Avatar
                        flex
                        width="40px"
                        height="40px"
                        border="1px solid #ffffff"
                      >
                        <AvatarLetter>
                          {row.uname?.charAt(0).toUpperCase()}
                        </AvatarLetter>
                      </Avatar>
                    ) : (
                      <Image
                        src={FileURL + row.img_url}
                        alt="image"
                        borderRadius="40px"
                        height="40px"
                        width="40px"
                        mr="12px"
                      />
                    )}
                    <Row
                      flexDirection="column"
                      width="87%"
                      padding="0px 0px 0px 0px"
                    >
                      <Text
                        fontSize="14px"
                        color="#2E2E2E"
                        fontFamily="Urbanist"
                        fontWeight="600"
                        lineHeight="normal"
                      >
                        Appointment Declined
                      </Text>
                      <Text
                        mt="5px"
                        fontFamily="Urbanist"
                        fontSize="12px"
                        color="rgba(46, 46, 46, 0.50)"
                        fontWeight="400"
                        lineHeight="14px"
                      >
                        {row.uname} , {row.fac_name}
                      </Text>
                      <Row
                        dis="flex"
                        justifyContent="space-between"
                        width="100%"
                        marginTop="6px"
                      >
                        <Text
                          fontFamily="Urbanist"
                          fontSize="12px"
                          color="rgba(46, 46, 46, 0.50)"
                          fontWeight="400"
                          lineHeight="14px"
                        >
                          {moment(row.currentTime).format("DD-MM-YYYY hh:mm A")}
                        </Text>
                      </Row>
                      <Row
                        dis="flex"
                        justifyContent="space-between"
                        width="100%"
                        marginTop="6px"
                      >
                        <Text
                          fontFamily="Urbanist"
                          fontSize="12px"
                          fontWeight="500"
                          color="rgba(46, 46, 46, 0.75)"
                          lineHeight="normal"
                        >
                          I apologize for any inconvenience and would be happy
                          to suggest a new date
                        </Text>
                      </Row>
                      <Row
                        dis="flex"
                        justifyContent="space-between"
                        width="100%"
                        marginTop="8px"
                      >
                        <Text
                          fontFamily="Manrope"
                          fontWeight="600"
                          fontSize="14px"
                          color="#2C7BE5"
                          lineHeight="normal"
                          onClick={() => {
                            navigate("/appointment/add_appointment");
                            window.location.reload();
                            props.closepopup();
                          }}
                        >
                          Reschedule
                        </Text>
                      </Row>
                    </Row>
                  </Row>
                )}

                {/* App Decline  Array end */}

                {/* App Cancelled  Array start */}
                {row.names === "app_delete" && (
                  <Row
                    key={ind}
                    borderBottom="1px rgba(46, 46, 46, 0.25) solid"
                    dis="flex"
                    height="143px"
                    padding="12px 0px 12px 12px"
                    cursor="pointer"
                  >
                    {DeleteShowHide === false && (
                      <Checkbox
                        type="checkbox"
                        name={row.id}
                        onClick={(e) => clickCheckbox(e)}
                        checked={row?.isChecked || false}
                      ></Checkbox>
                    )}
                    {row.img_url === null || row.img_url === undefined ? (
                      <Avatar
                        flex
                        width="40px"
                        height="40px"
                        border="1px solid #ffffff"
                      >
                        <AvatarLetter>
                          {row.uname?.charAt(0).toUpperCase()}
                        </AvatarLetter>
                      </Avatar>
                    ) : (
                      <Image
                        src={FileURL + row.img_url}
                        alt="image"
                        borderRadius="40px"
                        height="40px"
                        width="40px"
                        mr="12px"
                      />
                    )}
                    <Row
                      flexDirection="column"
                      width="87%"
                      padding="0px 0px 0px 0px"
                    >
                      <Text
                        fontSize="14px"
                        color="#2E2E2E"
                        fontFamily="Urbanist"
                        fontWeight="600"
                        lineHeight="normal"
                      >
                        Appointment Canceled
                      </Text>
                      <Text
                        mt="5px"
                        fontFamily="Urbanist"
                        fontSize="12px"
                        color="rgba(46, 46, 46, 0.50)"
                        fontWeight="400"
                        lineHeight="14px"
                      >
                        {row.uname} , {row.fac_name}
                      </Text>
                      <Row
                        dis="flex"
                        justifyContent="space-between"
                        width="100%"
                        marginTop="6px"
                      >
                        <Text
                          fontFamily="Urbanist"
                          fontSize="12px"
                          color="rgba(46, 46, 46, 0.50)"
                          fontWeight="400"
                          lineHeight="14px"
                        >
                          {moment(row.app_date_time).format(
                            "DD-MM-YYYY hh:mm A"
                          )}
                        </Text>
                      </Row>
                      <Row
                        dis="flex"
                        justifyContent="space-between"
                        width="100%"
                        marginTop="6px"
                      >
                        <Text
                          fontFamily="Urbanist"
                          fontSize="12px"
                          fontWeight="500"
                          color="rgba(46, 46, 46, 0.75)"
                          lineHeight="normal"
                        >
                          I apologize for any inconvenience and would be happy
                          to suggest a new date
                        </Text>
                      </Row>
                      <Row
                        dis="flex"
                        justifyContent="space-between"
                        width="100%"
                        marginTop="8px"
                      >
                        <Text
                          fontFamily="Manrope"
                          fontWeight="600"
                          fontSize="14px"
                          color="#2C7BE5"
                          lineHeight="normal"
                          onClick={(e) => reschedule(row.id)}
                        >
                          Reschedule
                        </Text>
                      </Row>
                    </Row>
                  </Row>
                )}

                {/* App Cancelled  Array End */}

                {/* Helath Gorilla  Array start */}
                {row.names === "health_gorila" && (
                  <Row
                    key={ind}
                    borderBottom="1px rgba(46, 46, 46, 0.25) solid"
                    dis="flex"
                    height="80px"
                    padding="12px 0px 12px 12px"
                    cursor="pointer"
                  >
                    {DeleteShowHide === false && (
                      <Checkbox
                        type="checkbox"
                        name={row.id}
                        onClick={(e) => clickCheckbox(e)}
                        checked={row?.isChecked || false}
                      ></Checkbox>
                    )}
                    {row.img_url === null || row.img_url === undefined ? (
                      <Avatar
                        flex
                        width="40px"
                        height="40px"
                        border="1px solid #ffffff"
                      >
                        <AvatarLetter>
                          {row.uname?.charAt(0).toUpperCase()}
                        </AvatarLetter>
                      </Avatar>
                    ) : (
                      <Image
                        src={FileURL + row.img_url}
                        alt="image"
                        borderRadius="40px"
                        height="40px"
                        width="40px"
                        mr="12px"
                      />
                    )}
                    <Row
                      flexDirection="column"
                      width="87%"
                      padding="0px 0px 0px 0px"
                    >
                      <Text
                        fontSize="14px"
                        color="#2E2C34"
                        fontFamily="Urbanist"
                        fontWeight="600"
                        lineHeight="17px"
                      >
                        Lab Results
                      </Text>

                      {row.UpdatedDescDate && row.UpdatedDescDate !== "" && (
                        <Text
                          mt="8px"
                          fontFamily="Urbanist"
                          fontSize="12px"
                          color="rgba(46, 46, 46, 0.50)"
                          fontWeight="400"
                          lineHeight="14px"
                          onClick={(e) => documentlaborder(row.id)}
                        >
                          Click here to view the lab results ({row.tests}-
                          {row.status} )
                        </Text>
                      )}
                    </Row>
                  </Row>
                )}

                {/* Helath Gorilla  Array End */}

                {/* Lab Order  Array start */}
                {row.names === "lab_order" && (
                  <Row
                    key={ind}
                    borderBottom="1px rgba(46, 46, 46, 0.25) solid"
                    dis="flex"
                    height="80px"
                    padding="12px 0px 12px 12px"
                    cursor="pointer"
                  >
                    {DeleteShowHide === false && (
                      <Checkbox
                        type="checkbox"
                        name={row.id}
                        onClick={(e) => clickCheckbox(e)}
                        checked={row?.isChecked || false}
                      ></Checkbox>
                    )}
                    {row.img_url === null || row.img_url === undefined ? (
                      <Avatar
                        flex
                        width="40px"
                        height="40px"
                        border="1px solid #ffffff"
                      >
                        <AvatarLetter>
                          {row.uname?.charAt(0).toUpperCase()}
                        </AvatarLetter>
                      </Avatar>
                    ) : (
                      <Image
                        src={FileURL + row.img_url}
                        alt="image"
                        borderRadius="40px"
                        height="40px"
                        width="40px"
                        mr="12px"
                      />
                    )}
                    <Row
                      flexDirection="column"
                      width="87%"
                      padding="0px 0px 0px 0px"
                    >
                      <Text
                        fontSize="14px"
                        color="#2E2C34"
                        fontFamily="Urbanist"
                        fontWeight="600"
                        lineHeight="17px"
                      >
                        Lab Order
                      </Text>

                      {row.UpdatedDescDate && row.UpdatedDescDate !== "" && (
                        <Text
                          mt="8px"
                          fontFamily="Urbanist"
                          fontSize="12px"
                          color="rgba(46, 46, 46, 0.50)"
                          fontWeight="400"
                          lineHeight="14px"
                          onClick={(e) => healthprofilelaborder(row.id)}
                        >
                          Click here to view the lab results ({row.title}-
                          {row.order_status} )
                        </Text>
                      )}
                    </Row>
                  </Row>
                )}

                {/* Lab Order  Array End */}
              </>
            ))}
          </>
        ) : (
          <CommonDiv height="100%" width="100%" flexCenterAll>
            <EmptyData width="120px" />
          </CommonDiv>
        )}
      </Data>
    </>
  );
};

export default memo(Notification);
