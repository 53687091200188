import axios from "axios";
import { Decrypt_Value } from "../MiddleWare/EncryptDecrypt";

let DBHost;
var finalloginurl;
if (window.location.href.includes("localhost")) {
  DBHost = "default";
  finalloginurl = process.env.REACT_APP_BACKEND_URL;
} else {
  let DBConnection = window.location.hostname.split(".");

  if (
    DBConnection[0] !== "ehr" &&
    DBConnection[0] !== "ehr1" &&
    DBConnection[0] !== "test" &&
    DBConnection[0] !== "production" &&
    DBConnection[0] !== "mumbai"
  ) {
    DBHost = DBConnection[0];
    finalloginurl = process.env.REACT_APP_BACKEND_URL.replace("oemr", DBHost);
  } else {
    DBHost = "default";
    finalloginurl = process.env.REACT_APP_BACKEND_URL.replace(
      "oemr",
      DBConnection[0]
    );
  }
}

const baseURL = finalloginurl + DBHost + "/api";
let headers = {};
let access = localStorage.accesstoken
  ? Decrypt_Value(localStorage.accesstoken, "vozoportal")
  : "";
if (access.length > 0) {
  headers.Authorization = `${access}`;
} else {
  headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  };
}

const axiosInst = axios.create({
  baseURL,
  headers,
});

export default axiosInst;
