import styled from "styled-components";

export const Input = styled.input`
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  border: ${(p) => p.border && p.border};
  padding: 12px !important;
  outline: none;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #dbdbdb !important;

    padding: 12px !important;
  }
  -webkit-appearance: none;

  &:focus-visible {
    outline: none;
  }
  border-radius: 4px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
`;

export const Inputstyled = styled(Input)`
  display: ${(p) => p.display && p.display};
`;

export const DatePickerInput = styled(Inputstyled)`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  border-left: ${(p) => p.borderLeft && p.borderLeft};
  border-right: ${(p) => p.borderRight && p.borderRight};
  border-top: ${(p) => p.borderTop && p.borderTop};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #7e7c7c !important;
    padding: 0px 0px !important;
    font-size: 14px !important;
  }
`;
export const DayButtons = styled.button`
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding: ${(p) => p.padding && p.padding};
  border: ${(p) => p.border && p.border};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  color: ${(p) => p.color && p.color};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  &:disabled {
    background-color: #cccccc !important;
    color: #666666 !important;
    margin-top: 4px !important;
    border-radius: 0px !important;
  }
`;

export const P = styled.p`
  color: ${(p) => p.color && p.color};
  height: ${(p) => p.height && p.height};
  font-weight: ${(p) => p.weight && p.weight};
  font-family: ${(p) => p.family && p.family};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lheight && p.lheight};
  width: ${(p) => p.width && p.width};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  overflow: ${(p) => p.overflow && p.overflow};
  margin: ${(p) => p.margin && p.margin};
`;

export const Imagecalendar = styled.img`
  position: absolute;
  right: 10px;
  width: 16px;
  height: 16px;
  object-fit: contain;
  cursor: pointer;
  margin-bottom: 2px;
`;

export const Div = styled.div`
  display: ${(p) => p.dis && p.dis};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  position: ${(p) => p.position && p.position};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  min-height: ${(p) => p.minheight && p.minheight};
  max-height: ${(p) => p.maxheight && p.maxheight};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.radius && p.radius};
  outline: none;
  font-family: ${(p) => p.family && p.family}, sans-serif;
  font-weight: ${(p) => p.weight && p.weight};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lineheight && p.lineheight};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  flex-wrap: ${(p) => p.flexWrap && p.flexWrap};
`;

export const Span = styled.span`
  font-family: "manrope", sans-serif;
  margin-left: 10px;
`;
