/* eslint-disable no-undef */
import styled from "styled-components";
import { BsXLg } from "react-icons/bs";
import {
  BiPlay,
  BiPause,
  BiFullscreen,
  BiExitFullscreen,
} from "react-icons/bi";

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;
  width: 100%;
  // height: 66vh;
  border-top: 1px solid #e2e8ed;
  padding-top: 20px;
  // overflow-y: scroll;
  padding-left: 40px;
  margin-left: -40px;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  text-align: justify;
`;

export const Label = styled.label`
  font-family: "Inter", sans-serif;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
  margin-left: 4px;
  color: ${({ color }) => color && color};
  cursor: pointer;
`;

export const InputBox = styled.input`
  margin-left: 12px;
  padding: 8px 12px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  outline: none;
  font-family: "Inter", sans-serif;
`;

export const ViewFlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
  background: #f7f7f7;
  border-radius: 6px;
  height: 32px;
`;

export const IconStyle = styled.div`
  cursor: pointer;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px 0px 5px;
  padding: 6px;
  height: 28px;
  background: ${({ active }) => active && "#ffffff"};
  box-shadow: ${({ active }) =>
    active &&
    "0px 0px 6px rgba(0, 0, 0, 0.06), 0px 2px 3px rgba(0, 0, 0, 0.1)"};
  border-radius: 5px;
  & svg {
    width: 16px !important;
    height: 16px !important;
  }
`;

export const TableContainer = styled.div`
  margin-left: -40px;
  // overflow-y: auto;
  // height: 66vh;
  min-height: ${({ minHeight }) => minHeight && minHeight};
`;

export const Image = styled.img`
  height: ${({ height }) => (height ? height : "28px")};
  width: ${({ width }) => (width ? width : "28px")};
  margin-right: ${({ margin }) => margin && "8px"};
  cursor: ${({ cursor }) => cursor && "pointer"};
`;

export const Avatar = styled.div`
  border-radius: 50%;
  width: 28px;
  min-width: 28px;
  height: 28px;
  background-color: #a8aebe;
  display: inline-block;
  margin-right: 12px;
  border: ${(p) => p.border && p.border};
`;

export const AvatarImage = styled(Image)`
  min-width: ${(p) => p.minWidth && p.minWidth};
  border: ${(p) => p.border && p.border};
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
  margin-top: 3px;
`;

export const AvatarLetter = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  display: ${({ display }) => display && display};
  padding-top: ${({ paddingTop }) => paddingTop && paddingTop};
  justify-content: ${({ justifyContent }) => justifyContent && justifyContent};
`;

export const StatusLabel = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  border-radius: 4px;
  padding: 4px 8px;
  background: ${({ background }) => background && background};
  color: ${({ color }) => color && color};
`;

export const Card = styled.div`
  width: ${({ size }) => (size ? size : "337px")};
  border: 1px solid #ebeaed;
  border-radius: 8px;
  margin-bottom: 32px;
  cursor: pointer;
  margin-right: 15px;
  height: fit-content;
`;

export const Thumpnail = styled.img`
  width: 100%;
  height: 187px;
  margin: 0;
  border-radius: 8px 8px 0px 0px;
`;

export const Flexbetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-item: center;
  margin: ${({ margin }) => (margin ? margin : "10px 16px 20px 16px")};
  border-top: ${({ borderTop }) => borderTop && borderTop};
  padding: ${({ padding }) => padding && padding};
`;

export const TitleLabel = styled.label`
  font-family: ${({ fontFamily }) =>
    fontFamily ? fontFamily : "Work Sans" + ",sans-serif"};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "600")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  line-height: ${({ fontHeight }) => (fontHeight ? fontHeight : "19px")};
  color: ${({ color }) => (color ? color : "#333333")};
  margin-left: ${({ margin }) => margin && "20px"};
  width: ${({ width }) => (width ? width : "80%")};
  white-space: ${({ whiteSpace }) => (whiteSpace ? whiteSpace : "nowrap")};
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : "4px")};
  overflow-wrap: ${({ overflowWrap }) => overflowWrap && overflowWrap};
  &.problem_label {
    width: 400px;
  }
  @media screen and (max-width: 1200px) {
    &.problem_label {
      width: 220px;
    }
  }
  @media screen and (max-width: 950px) {
    &.problem_label {
      width: 180px;
    }
  }
`;

export const Badge = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #00a5ff;
  background: rgba(0, 165, 255, 0.1);
  border-radius: 4px;
  padding: 4px 12px;
`;

export const DiscLabel = styled.p`
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #545f82;
  margin: 8px 16px;
  height: 51px;
  text-align: justify;
  word-break: ${({ wordBreak }) => wordBreak && wordBreak};
`;

export const UserLabbel = styled.label`
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "19px")};
  color: #333333;
  margin-left: -5px;
  &.assigned_margin {
    margin-left: -5px;
    font-size: 14px;
  }
  &.assigned_marginright {
    margin-left: 8px;
    font-size: 14px;
    margin-right: -10px;
  }
  @media screen and (max-width: 950px) {
    &.assigned_margin {
      margin-left: -23px;
      font-size: 13px;
    }
    &.assigned_marginright {
      margin-left: 5px;
      font-size: 14px;
      margin-right: -23px;
    }
  }
`;

export const ContainerCenter = styled.div`
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -48%);
  background: #ffffff;
  border: 1px solid #cbd5e0;
  box-shadow: 5px 5px 8px rgba(20, 46, 110, 0.15);
  width: 50%;
  height: 93%;
  border-radius: 8px;
`;

export const FlexTitle = styled.span`
  display: flex;
  padding: 22px 20px 20px 20px;
  border-bottom: ${({ border }) => border && "1px solid #cbd5e0"};
`;

export const FrameContainer = styled.div`
  margin: 18px 82px 9px 83px;
  // postion: absolute;
  height: 89%;
`;

export const IFrame = styled.iframe`
  height: ${({ height }) => (height ? height : "97%")};
  width: 100%;
  border: none;
`;

export const TableHead = styled.thead`
  top: 0;
  position: sticky;
  background-color: #f7f7f7;
  text-align: left;
`;

export const Table = styled.table`
  width: 100%;
  position: relative;

  th {
    position: sticky;
    top: 0;
    background-color: red;
    color: white;
  }
  td {
    padding: 10px;
    text-align: left;
    margin: 0;
  }
`;

export const MainPlayerContaier = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  min-width: 100%;
  background-size: cover;
`;

export const PalyerContainer = styled.div`
  position: relative;
  border-radius: 16px;
  font-size: 0;
  overflow: hidden;
  height: 80%;
  width: 80%;
`;

export const PlayerVideo = styled.video`
  width: 100%;
`;

export const PlayerControl = styled.div`
  position: absolute;
  bottom: ${({ playIs }) => (playIs ? "20px" : "-35px")};
  width: 100%;
  transition: all 0.3s;
  padding: 0px 20px;
`;

export const TimeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const DurationLabel = styled.label`
  margin-right: 4px;
  font-family: "Inter", san-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.004em;
  color: #ffffff;
`;

export const RangeSlider = styled.input.attrs({ type: "range" })`
  height: 4px;
  width: 100%;
  // -webkit-appearance: none;
  border-radius: 5px;
  outline: none;
  // margin-left: 12px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
  background: rgba(255, 255, 255, 0.3);
  color: red;
  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    // -webkit-appearance: none;
    outline: none;
    border-radius: 50%;
    cursor: pointer;
    width: 16px;
    height: 16px;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(37, 33, 98, 0.2),
      0px 2px 10px rgba(37, 33, 98, 0.1);
  }
`;

export const Progress = styled.div`
  flex: 10;
  position: relative;
  display: flex;
  flex-basis: 100%;
  height: 5px;
  transition: height 0.3s;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

export const ProgressFill = styled.div`
  width: 100%;
  background: #2c7be5;
  flex: 0;
  flex-basis: ${({ fill }) => (fill ? fill : "0%")};
`;

export const ImageClose = styled(BsXLg)`
  display: none;
  position: absolute;
  z-index: 1001;
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: #ffffff;
  right: 30px;
  top: 20px;
`;

export const Play = styled(BiPlay)`
  position: absolute;
  cursor: pointer !important;
  height: 50px;
  width: 50px;
  top: 43%;
  left: 48%;
  & path {
    fill: #ffffff;
  }
`;

export const Pause = styled(BiPause)`
  // z-index: 1001;
  cursor: pointer !important;
  position: absolute;
  height: 50px;
  width: 50px;
  top: 43%;
  left: 48%;

  & path {
    fill: #ffffff;
  }
`;
export const Fullscreen = styled(BiFullscreen)`
  height: 18px;
  width: 18px;
  cursor: pointer;
  & path {
    fill: #ffffff;
  }
`;
export const ExitFullscreen = styled(BiExitFullscreen)`
  height: 18px;
  width: 18px;
  z-index: 1001;
  cursor: pointer;
  & path {
    fill: #ffffff;
  }
`;

export const PlayPause = styled.div`
  display: none;
`;
