import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  GetPatGenderDetails,
  GetPatMaritalStatus,
  GetPatLangDetails,
  GetPatRaceDetails,
  GetPatientState,
} from "../../StateManagement/Reducers/SettingState";

import TopNav from "../TopNav";
import EditProfile from "./EditProfile";
import Loading from "../StyledComponents/Loading";

import { BodyContainer } from "../StyledComponents";

const Settings = () => {
  const dispatch = useDispatch();

  const Refresh = useSelector((state) => state.Setting.Refresh);
  const ProfileLoading = useSelector((state) => state.Setting.Loading);
  const TopLoading = useSelector((state) => state.TopNavbar.UserLoading);

  useEffect(() => {
    dispatch(GetPatGenderDetails());
    dispatch(GetPatMaritalStatus());
    dispatch(GetPatLangDetails());
    dispatch(GetPatRaceDetails());
    dispatch(GetPatientState());
  }, [dispatch, Refresh]);
  return (
    <>
      <TopNav title="Edit Profile" />
      {TopLoading && ProfileLoading ? (
        <Loading />
      ) : (
        <BodyContainer paddingLeft="40px" paddingTop="13px">
          <EditProfile />
        </BodyContainer>
      )}
    </>
  );
};

export default Settings;
