import styled from "styled-components";
import arrow from "../../../Assets/images/arrowdown.png";

export const CurveImg = styled.img`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  position: ${(p) => p.position && p.position};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
`;

export const Display = styled.div`
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => p.alignItems && p.alignItems};
  margin-bottom: ${(p) => p.mb && p.mb};
  margin-top: ${(p) => p.mt && p.mt};
  padding-bottom: ${(p) => p.pb && p.pb};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  width: ${(p) => p.width && p.width};
`;

export const Inputbox = styled.input`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  font-size: ${(p) => p.fontSize && p.fontSize};
  margin-right: ${(p) => p.mr && p.mr};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.mb && p.mb};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  cursor: ${(p) => p.cursor && p.cursor};
  &::placeholder,
  &::-webkit-input-placeholder {
    color: #7e7c7c !important;
  }
  &::-webkit-file-upload-button {
    font-size: 14px;
    font-weight: 500;
    font-family: "'Inter',sans-serif";
  }
  &:focus {
    outline: none;
    box-shadow: 0px 0px 2px #cbd5e0;
  }
  &::file-selector-button {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    color: #0d0c22;
    padding: 4px 6px;
    background: #e9e9e9;
    border: 1px solid #b3b3b3;
    border-radius: 2px;
  }
`;

export const Dropdown = styled.select`
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  color: ${(p) => p.color && p.color};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  margin-right: ${(p) => p.mr && p.mr};
  margin-bottom: ${(p) => p.mb && p.mb};
  padding-left: ${(p) => p.pl && p.pl};
  // -webkit-appearance: none;
  // &:after {
  //   content: "";
  //   background: url(${arrow}) no-repeat right;
  //   width: 100px;
  //   height: 100px;
  //   position: absolute;
  //   display: block;
  // }
  &:focus {
    outline: none;
    box-shadow: 0px 0px 2px #cbd5e0;
  }
`;
export const CustomOption = styled.option`
  /* Add styles for your options here if needed */
`;
export const SelectInput = styled.div`
  width: ${(p) => p.width && p.width};
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  height: ${(p) => p.height && p.height};
  border: ${(p) => p.border && p.border};
  background: ${(p) => p.background && p.background};
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  padding-left: ${(p) => p.pl && p.pl};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  position: relative;
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overFlow && p.overFlow};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  &:empty:before {
    content: ${(p) => p.placeholder && p.placeholder};
    color: rgba(46, 46, 46, 0.5);
    font-weight: 400;
    font-size: 14px;
  }
`;
export const NormalImage1 = styled.div`
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  position: ${(p) => p.position && p.position};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  background: ${(p) => p.background && p.background};
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
`;

export const ModalDialog = styled.div`
  width: ${(p) => (p.width ? p.width : "544px")};
  background: #ffffff;
  position: relative;
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 12px;
  transition: transform 3s;
  animation: ${(p) =>
    p.animation ? "show_1 0.1s ease-in-out" : "show_2 0.1s ease-in-out"};
  padding: ${(p) => p.padding && p.padding};
`;

export const ModalContent1 = styled.div`
  position: fixed;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.06), 0px 16px 16px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  animation: modal 1s linear;
  top: ${(p) => (p.top ? p.top : "25px")};
  left: ${(p) => p.left && p.left};
  transform: translateY(100%);
  background: ${(p) => (p.background ? p.background : " #ffffff")};
  border-left: ${(p) => (p.borderLeft ? p.borderLeft : "8pxsolid #ff8181")};
  align-items: "center";
  justify-content: "center";
  transition: all 0.1s;
  @keyframes modal {
    0% {
      transform: translateY(-250%);
    }
    100% {
      transform: translateY(100%);
    }
  }
`;

export const Hrline = styled.hr`
  border: 1px solid #d8e0f0;
  margin-top: ${(props) => (props.mt ? props.mt : "20px")};
  margin-bottom: ${(p) => (p.mb ? p.mb : "20px")};
  margin-left: ${(p) => (p.mmarginLeft ? p.marginLeft : "-40px")};
  border-bottom: none;
`;

export const Displayerror = styled.div`
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => p.alignItems && p.alignItems};
  margin-bottom: ${(p) => p.mb && p.mb};
  margin-top: ${(p) => p.mt && p.mt};
  background: ${(p) => p.background && p.background};
  padding-bottom: ${(p) => p.pb && p.pb};
  padding-top: ${(p) => p.pt && p.pt};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  width: ${(p) => p.width && p.width};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  color: ${(p) => p.color && p.color};
`;

export const DisplayerrorText = styled.p`
  color: ${(p) => p.color && p.color};
  text-align: ${(p) => p.textAlign && p.textAlign};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  margin-top: ${(p) => p.mt && p.mt};
`;

// Vanitha 19-09-22

export const DragDrop = styled.div`
  background: #fafafa;
  border: 1px dashed #e5e5e5;
  border-radius: 4px;
  margin-top: 20px;
  text-align: center;
`;
// Vanitha 19-09-22

// Vanitha 20-09-22

export const FileInput = styled.input`
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer !important;
  width: 280px;
  height: 117px;
  margin: 139px 11px 3px 103px;
`;

// Vanitha 20-09-22
export const DropdownImage = styled.img`
  height: ${(p) => (p.height ? p.height : "8px")};
  width: ${(p) => (p.width ? p.width : "12px")};
  position: absolute;
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  top: ${(p) => p.top && p.top};
  right: ${(p) => p.right && p.right};
`;
export const DropdownContainer = styled.div`
  position: relative;
  display: ${(p) => (p.display ? p.display : "inline-block")};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
`;
export const DropDownDiv = styled.div`
  margin: ${(p) => p.margin && p.margin};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  background: ${(p) => p.background && p.background};
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  display: grid;
  padding: ${(p) => p.padding && p.padding};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  border: ${(p) => p.border && p.border};
  position: absolute;
  z-index: 1000;
  min-height: ${(p) => p.minHeight && p.minHeight};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  left: ${(p) => p.left && p.left};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  overflow: ${(p) => p.overflow && p.overflow};
  bottom: ${(p) => p.bottom && p.bottom};
`;

export const DropdownOptions = styled.label`
  display: ${(p) => p.display && p.display};
  color: ${(p) => p.color && p.color};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "400")};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "19px")};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  padding: ${(p) => p.padding && p.padding};
  font-size: ${(p) => p.fontSize && p.fontSize};
  &:hover {
    background-color: #2c7be5 !important;
    color: #ffffff !important;
  }
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    `
    color: gray; /* Apply a grayed-out color for disabled option */
    pointer-events: none; /* Disable click events for disabled option */
  `}

  &:hover {
    ${(props) =>
      !props.disabled &&
      `
      background-color: #2c7be5 !important;
      color: #ffffff !important;
    `}
  }
`;

export const TooltipText = styled.span`
  position: absolute;
  left: 0;
  bottom: 32px;
  word-break: break-word;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: pre-wrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  z-index: 1002;
  background-color: #f5f5f5;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  color: rgb(45, 55, 72);
  font-size: 12px;
`;

export const Tooltip = styled.div`
  position: relative;
  cursor: pointer;
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  &:hover .TooltipText {
    opacity: 1;
    visibility: visible;
  }

  &:focus-within .TooltipText {
    opacity: 0;
    visibility: hidden;
  }
`;
export const TooltipComponent = styled.label`
  margin: ${(p) => p.margin && p.margin};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  background: ${(p) => p.background && p.background};
  background-clip: ${(p) => p.backgroundClip && p.backgroundClip};
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  display: grid;
  padding: ${(p) => p.padding && p.padding};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  border: ${(p) => p.border && p.border};
  position: absolute;
  z-index: 1000;
  min-height: ${(p) => p.minHeight && p.minHeight};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  left: ${(p) => p.left && p.left};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  overflow: ${(p) => p.overflow && p.overflow};
  bottom: ${(p) => p.bottom && p.bottom};
`;
