import React, { useState, useEffect, useRef, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";

import { FiDownload } from "react-icons/fi";
import moment from "moment";

import {
  getUpdateDocumentslist,
  getDeleteDocumentslist,
  getPreviewDocument,
  getViewEditDocument,
  getDeleteTempDoc,
  getPreviewDownload,
  getDocumentslist,
  getEditDataEmpty,
} from "../../../StateManagement/Reducers/DocumentsState";

import CreateSignature from "../CreateSignature";
import ViewEdit from "../ViewEdit";
import EmptyData from "../../StyledComponents/EmptyData";
import Error from "../../../Modules/StyledComponents/Toastify/Error";

import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableHeadCell,
  TableBodyCell,
  Image,
  FlexJustCenter,
} from "../../StyledComponents";
import {
  TableDiv,
  ProfileNameText,
  StatusDiv,
  StatusText,
  DropdownList,
  DropdownContent,
  Iframe,
  LinkA,
} from "./styles";

import { BsThreeDots } from "react-icons/bs";
import pencil from "../../../Assets/images/pencil.png";
import Download from "../../../Assets/images/Download.png";
import Delete from "../../../Assets/images/Delete.png";
import forward from "../../../Assets/images/forward.png";
import printer from "../../../Assets/images/printer.png";
import { TitleLabel } from "../../HomeWork/Styles";

const DocumentTable = ({ docTableData, documenthead }) => {
  const dispatch = useDispatch();
  const componentRef = useRef();

  const [editopen, setEditopen] = useState("");
  const [iscreate, setIsCreate] = useState();
  const [forprint, setforprint] = useState("");
  const [tosign, settosign] = useState("");
  const [documentlistdata, setdocumentlistdata] = useState([]);
  const [donwloadprintid, setdonwloadprintid] = useState("");
  const [ForViewEdit, setForViewEdit] = useState(false);
  const [FileName, setFileName] = useState("");
  const [FileStatus, setFileStatus] = useState("");
  const [ForSign, setForSign] = useState("");
  const [FileId, setFileId] = useState("");

  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;

  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }
  const FileURL = backurl + "/src/Services/documentimg/";

  const FileURLProfile = backurl + "/sites/" + DBhost + "/documentimg/";

  const PreviewDownload = useSelector(
    (state) => state.Document.previewdownload
  );
  const DocumentDownloadUrl = useSelector(
    (state) => state.Document.DownloadDocumentlist
  );

  const setEditAction = (ind) => {
    setEditopen((prev) => (prev === ind ? "" : ind));
  };

  const SentToReview = (ind, id, status) => {
    if (status === "In Review") {
      toast(<Error msg="This Document Already Sent to Review" />, {
        containerId: "B",
        className: "error_badge",
      });

      setEditAction(ind);
    } else if (status === "Locked") {
      toast(<Error msg="This Document is Already Locked" />, {
        containerId: "B",
        className: "error_badge",
      });
      setEditAction(ind);
    } else {
      dispatch(getUpdateDocumentslist({ ids: id }));

      setEditAction(ind);
    }
  };

  const HandlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => dispatch(getDeleteTempDoc({ id: donwloadprintid })),
  });

  const FileDownload = async (fileurl, id, ind) => {
    setdonwloadprintid(id);
    dispatch(getPreviewDownload({ file: fileurl, id: id, testurl: FileURL }));
    setEditAction(ind);
  };

  const HandleDelete = (ind, id) => {
    if (window.confirm("Are you sure you want to Delete?")) {
      dispatch(getDeleteDocumentslist({ ids: id }));

      setEditAction(ind);
    }
  };

  const PreviewDocument = (id, fileurl, print, ind) => {
    setdonwloadprintid(id);
    if (print === "yes") {
      setEditAction(ind);
      setforprint("yes");
    }
    dispatch(getPreviewDocument({ file: fileurl, id: id }));
  };
  const PreviewDocumentEdit = (id, fileurl, fname, status, sign) => {
    dispatch(getViewEditDocument({ file: fileurl, id: id }));
    setForViewEdit(true);
    setFileName(fname);
    setFileStatus(status);
    setForSign(sign);
    setFileId(id);
  };

  const HanldeSignature = (ind, id, status) => {
    if (status === "In Review") {
      toast(<Error msg="This Document Already Signatured!." />, {
        containerId: "B",
        className: "error_badge",
      });

      setEditAction(ind);
    } else {
      settosign(id);
      setIsCreate(true);
      setEditAction(ind);
    }
  };

  const HanldeCloseSignature = () => {
    setIsCreate(false);
    settosign("");
  };
  const HanldeCloseViewEdit = () => {
    setForViewEdit(false);
    dispatch(getDocumentslist({ filtertype: "All Documents" }));
    dispatch(getEditDataEmpty());
  };

  const getFileExtension = (filename) => {
    const extension = filename.split(".").pop();
    return extension;
  };

  const handleClick = (e) => {
    if (!e.target.id) {
      setEditopen("");
    }
  };

  const truncateText = (text, maxLength) => {
    if (text?.split("").length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // ------------------------------Functions End-------------------------- //

  // ------------------------------UseEffect Start-------------------------- //
  useEffect(() => {
    if (docTableData !== null || docTableData !== undefined) {
      const sortedArray = docTableData.sort((a, b) => {
        if (a.assignedByDefault === 1 && b.assignedByDefault !== 1) {
          return -1;
        }

        if (b.assignedByDefault === 1 && a.assignedByDefault !== 1) {
          return 1;
        }

        return b.id - a.id;
      });
      setdocumentlistdata(sortedArray);
    }
  }, [docTableData]);

  useEffect(() => {
    if (DocumentDownloadUrl !== "") {
      if (forprint === "yes") {
        const fileextension = getFileExtension(DocumentDownloadUrl);
        if (
          fileextension === "png" ||
          fileextension === "txt" ||
          fileextension === "jpg" ||
          fileextension === "jpeg" ||
          fileextension === "PNG" ||
          fileextension === "TXT" ||
          fileextension === "JPG" ||
          fileextension === "JPEG"
        ) {
          HandlePrint();
        } else if (fileextension === "pdf" || fileextension === "PDF") {
          document.getElementById("preview_document").click();
          setTimeout(() =>
            dispatch(getDeleteTempDoc({ id: donwloadprintid }), 10000)
          );
        } else {
          toast(<Error msg="File Not Allow to Print." />, {
            containerId: "B",
            className: "error_badge",
          });
        }
        setforprint("");
      } else {
        document.getElementById("preview_document").click();
        setTimeout(() =>
          dispatch(getDeleteTempDoc({ id: donwloadprintid }), 10000)
        );
      }
    }
  }, [DocumentDownloadUrl]);

  useEffect(() => {
    if (PreviewDownload !== "") {
      var tag = document.createElement("a");
      tag.href = PreviewDownload.file;
      tag.download = PreviewDownload.filename;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
      dispatch(getDeleteTempDoc({ id: donwloadprintid }));
      setdonwloadprintid("");
    }
  }, [PreviewDownload]);

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  // ------------------------------UseEffect End-------------------------- //

  return (
    <>
      <TableDiv>
        <Iframe
          seamless="seamless"
          width="100%"
          height="800px"
          ref={componentRef}
          src={FileURL + DocumentDownloadUrl}
        />
        <LinkA
          href={FileURL + DocumentDownloadUrl}
          target="_blank"
          id="preview_document"
        />

        <Table>
          <TableHead>
            <TableRow background="#F7F7F7">
              <TableHeadCell padding="20px" width="15%">
                Document
              </TableHeadCell>
              <TableHeadCell padding="20px" width="20%">
                Create Date
              </TableHeadCell>
              <TableHeadCell padding="20px" width="20%">
                Shared By
              </TableHeadCell>
              <TableHeadCell width="8%" padding="20px 0px 20px 70px">
                Status
              </TableHeadCell>
              <TableHeadCell width="7%"></TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {documentlistdata.length > 0 ? (
                <>
                  {documentlistdata.map((row, ind) => (
                    <TableRow background="#FFFFF" key={ind}>
                      <TableBodyCell
                        fontFamily="'Inter',sans-serif"
                        padding="16px 20px 10px 20px;"
                        cursor="pointer"
                        width="25%"
                        whiteSpace="normal"
                        overflowWrap="break-word"
                        onClick={() =>
                          row.status === "In Review" ||
                          row.status === "Locked" ||
                          row.is_share !== "yes"
                            ? PreviewDocument(row.id, row.file_url, "no", ind)
                            : row.tags === "By Provider"
                            ? PreviewDocument(row.id, row.file_url, "no", ind)
                            : PreviewDocumentEdit(
                                row.id,
                                row.file_url,
                                row.form_name,
                                row.status,
                                row.req_pat_sign
                              )
                        }
                      >
                        <FlexJustCenter>
                          <TitleLabel
                            cursor="pointer"
                            fontFamily="'Inter',sans-serif"
                            fontSize="14px"
                            fontHeight="16px"
                            fontWeight="normal"
                            color="#000000"
                            title={row.form_name}
                          >
                            {truncateText(row.form_name, 26)}
                          </TitleLabel>
                        </FlexJustCenter>
                      </TableBodyCell>
                      <TableBodyCell
                        fontFamily="'Inter',sans-serif"
                        padding="16px 20px 10px 20px;"
                      >
                        {row.template_date
                          ? moment(row.template_date).format(
                              "MM-DD-YYYY hh:mm A"
                            )
                          : row.date === "Today"
                          ? "Today"
                          : row.date === "Yesterday"
                          ? "Yesterday"
                          : row.date
                          ? moment(row.date).format("MM-DD-YYYY hh:mm A")
                          : null}
                      </TableBodyCell>

                      <TableBodyCell
                        display="flex"
                        fontFamily="'Inter',sans-serif"
                        padding="16px 20px 10px 20px;"
                        alignItems="center"
                        width="max-content"
                      >
                        {row.prov_img_url !== null ? (
                          <Image
                            objectFit="cover"
                            loading="lazy"
                            width="28px"
                            height="28px"
                            borderRadius="50%"
                            margin="0 12px 0 0"
                            src={FileURLProfile + row.prov_img_url}
                          />
                        ) : (
                          <ProfileNameText>
                            {row.prov_name_split}
                          </ProfileNameText>
                        )}
                        {row.username}
                      </TableBodyCell>
                      <TableBodyCell padding="16px 0px 10px 38px;">
                        {row.status === "In Review" && (
                          <StatusDiv
                            width="80px"
                            background="rgba(32, 201, 172, 0.1)"
                          >
                            <StatusText color="#20c9ac">
                              {row.status}
                            </StatusText>
                          </StatusDiv>
                        )}
                        {row.status === "New" && (
                          <StatusDiv
                            width="50px"
                            background=" rgba(0, 165, 255, 0.1)"
                          >
                            <StatusText color="#00a5ff">
                              {row.status}
                            </StatusText>
                          </StatusDiv>
                        )}
                        {row.status === "Locked" && (
                          <StatusDiv
                            width="80px"
                            background="rgba(132, 129, 138, 0.1)"
                          >
                            <StatusText color="#2e2c34">
                              {row.status}
                            </StatusText>
                          </StatusDiv>
                        )}
                      </TableBodyCell>
                      <TableBodyCell
                        padding="16px 40px 10px 38px;"
                        cursor="pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditAction(ind);
                        }}
                      >
                        <BsThreeDots
                          id="icon"
                          style={{
                            fontSize: 24,
                            color: "#212121",
                            cursor: "pointer",
                          }}
                          // onClick={() => setEditAction(ind)}
                        />
                        <DropdownContent
                          marginLeft="-180px"
                          width="179px"
                          marginRight="16px"
                          display={editopen === ind ? "true" : "false"}
                          share={row.is_share}
                        >
                          {row.is_share !== "yes" && (
                            <>
                              {row.status !== "Locked" && (
                                <DropdownList
                                  id="list"
                                  padding="0px 0px 3px 20px"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    SentToReview(ind, row.id, row.status);
                                  }}
                                >
                                  <Image
                                    objectFit="cover"
                                    width="16px"
                                    height="16px"
                                    margin="8px 16px 0px 0px"
                                    src={forward}
                                    id="doc_rvw-snt_img"
                                  />
                                  Sent to Review
                                </DropdownList>
                              )}
                            </>
                          )}
                          {/* {row.is_share === "yes" && (
                              <>
                                {row.req_pat_sign === "1" && (
                                  <>
                                    {row.status !== "Locked" &&
                                      row.status !== "In Review" && (
                                        <DropdownList
                                          id="list1"
                                          padding="0px 0px 3px 20px"
                                          onClick={() =>
                                            HanldeSignature(
                                              ind,
                                              row.id,
                                              row.status
                                            )
                                          }
                                        >
                                          <Image
                                            objectFit="cover"
                                            width="16px"
                                            height="16px"
                                            margin="0px 16px 0px 0px"
                                            src={pencil}
                                          />
                                          Signature
                                        </DropdownList>
                                      )}
                                  </>
                                )}
                              </>
                            )} */}
                          {(row.status === "In Review" ||
                            (row.is_share !== "yes" &&
                              (row.status === "New" ||
                                row.status === "Locked" ||
                                row.status === "In Review"))) && (
                            <>
                              <DropdownList
                                id="list2"
                                padding="0px 0px 3px 20px"
                                onClick={(e) => {
                                  PreviewDocument(
                                    row.id,
                                    row.file_url,
                                    "yes",
                                    ind
                                  );
                                  e.stopPropagation();
                                }}
                              >
                                <Image
                                  loading="lazy"
                                  width="16px"
                                  height="16px"
                                  margin="8px 16px 0px 0px"
                                  src={printer}
                                />
                                Print
                              </DropdownList>
                              <DropdownList
                                id="list3"
                                padding="0px 0px 3px 20px"
                                onClick={(e) => {
                                  FileDownload(row.file_url, row.id, ind);
                                  e.stopPropagation();
                                }}
                              >
                                <Image
                                  loading="lazy"
                                  width="16px"
                                  height="16px"
                                  margin="8px 16px 0px 0px"
                                  src={Download}
                                />
                                Download
                              </DropdownList>
                            </>
                          )}
                          {row.status !== "Locked" && (
                            <DropdownList
                              id="list4"
                              padding="0px 0px 3px 20px"
                              onClick={(e) => {
                                HandleDelete(ind, row.id);
                                e.stopPropagation();
                              }}
                            >
                              <Image
                                loading="lazy"
                                width="16px"
                                height="16px"
                                margin="8px 16px 0px 0px"
                                src={Delete}
                              />
                              Delete
                            </DropdownList>
                          )}
                        </DropdownContent>
                      </TableBodyCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow background="#FFFFF">
                  <TableBodyCell colSpan={5} textAlign="center">
                    <EmptyData />
                  </TableBodyCell>
                </TableRow>
              )}
            </>
          </TableBody>
        </Table>
      </TableDiv>

      <CreateSignature
        show={iscreate}
        close={HanldeCloseSignature}
        ids={tosign}
      />
      <ViewEdit
        show={ForViewEdit}
        filename={FileName}
        filestatus={FileStatus}
        fileid={FileId}
        close={HanldeCloseViewEdit}
        forsign={ForSign}
      />
    </>
  );
};

export default memo(DocumentTable);
