import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setEmailRes } from "../../../StateManagement/Reducers/PreLoginState";
import { Encrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

import { toast } from "react-toastify";

import vozoLogo from "../../../Assets/images/Logo.png";
import {
  LoginPass,
  getCredentials,
} from "../../../StateManagement/Reducers/LoginState";

import Error from "../../../Modules/StyledComponents/Toastify/Error";
import { setMode } from "../../../StateManagement/Reducers/PreLoginState";

import {
  Button,
  LinK,
  FormLabel,
  Form,
  FormGroup,
} from "../../StyledComponents";
import { ImageContainer, Image, Container, Span } from "../StyledComponents";
import { Label, Div, Input } from "./styles";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const LoginRight = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const [PasswordShow, setPasswordShow] = useState(true);
  const [UserName, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [misMatch, setMissMatch] = useState(false);
  const [mailErr, setMailErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [errMsgPass, setErrMsgPass] = useState("");
  const [btnDisabled, setbtnDisabled] = useState(false);
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }
  const File_URL = backurl + "/sites/" + DBhost + "/documentimg/";

  const LoginResponse = useSelector((state) => state.Login.LoginRes);
  const text = useSelector((state) => state.PreLogin.logo);
  let accountUpdate =
    localStorage.getItem("account_update") &&
    Decrypt_Value(localStorage.getItem("account_update"), "vozoportal");

  const UserNameValidation = (e) => {
    setUsername(e.target.value);
    setMailErr(false);
  };

  const PasswordValidation = (e) => {
    setPassword(e.target.value);
    setMissMatch(false);
  };
  const handleMode = (a) => {
    if (a === "1") {
      dispatch(setMode("new"));
      dispatch(setEmailRes({ status: "", output: "" }));
    }
  };

  const Onsubmit = (e) => {
    setbtnDisabled(true);
    setTimeout(() => setbtnDisabled(false), 1000);
    e.preventDefault();
    if (UserName !== "" && Password !== "") {
      dispatch(
        getCredentials({
          UserName: UserName,
          Password: Password,
          source: "login",
        })
      );
    } else {
      if (UserName === "") {
        setErrMsg("Please enter the email address");
        setMailErr(true);
      }
      if (Password === "") {
        console.log("password");
        setErrMsgPass("Please enter the password");
        setMissMatch(true);
      }
    }
  };

  useEffect(() => {
    if (LoginResponse.status === "Username is Incorrect") {
      setMailErr(true);
      setErrMsg("Enter the Correct MailID");
      toast(<Error msg="Username is incorrect!." />, {
        containerId: "B",
        className: "error_badge",
      });
    } else if (LoginResponse.status === "Password is Incorrect") {
      setMissMatch(true);
      setErrMsgPass("Enter the Correct Password");
      toast(<Error msg="Password is incorrect!." />, {
        containerId: "B",
        className: "error_badge",
      });
    }
    if (LoginResponse.status === "LOGIN_SUCCESS") {
      if (LoginResponse.AllowPortal === "YES") {
        localStorage.setItem(
          "PID",
          Encrypt_Value(LoginResponse.Pid, "vozoportal")
        );
        localStorage.setItem(
          "accesstoken",
          Encrypt_Value(LoginResponse.access_token, "vozoportal")
        );
        localStorage.setItem(
          "refreshtoken",
          Encrypt_Value(LoginResponse.refresh_token, "vozoportal")
        );

        localStorage.setItem(
          "token_exp",
          Encrypt_Value(LoginResponse.token_exp, "vozoportal")
        );
        localStorage.setItem(
          "idle",
          Encrypt_Value(LoginResponse.idle, "vozoportal")
        );

        localStorage.setItem(
          "account_type",
          Encrypt_Value(Number(LoginResponse.account_type), "vozoportal")
        );

        localStorage.setItem(
          "account_update",
          Encrypt_Value(Number(LoginResponse.account_update), "vozoportal")
        );

        localStorage.setItem(
          "trial_period",
          Encrypt_Value(
            String(LoginResponse.trial_period).toLowerCase() === "true",
            "vozoportal"
          )
        );

        setTimeout(() => {
          Navigate("/dashboard");
        }, 1000);
        window.location.reload();
      } else {
        Navigate("/AccessDenied");
      }
    }
  }, [LoginResponse]);

  return (
    <Container width="100%">
      {text.length !== 0 && (
        <ImageContainer marginTop="72px" marginBottom="65px">
          <Image
            loading="lazy"
            src={text !== "failed" ? File_URL + text : vozoLogo}
            height="41px"
            width="173px"
            objectFit="contain"
          />
        </ImageContainer>
      )}
      <Div width="400px" marginLeft="auto" marginRight="auto" marginTop="10%">
        <Div textAlign="left" display="grid">
          <Label
            size="36px"
            weight={700}
            textAlign="left"
            marginBottom="14px"
            lineHeight="44px"
          >
            Login
          </Label>
          <Span
            marginBottom="33px"
            color="#667085"
            fontSize="20px"
            lineHeight="27px"
          >
            Leading the way in managing your health.
          </Span>
        </Div>
        <Form onSubmit={Onsubmit}>
          <FormGroup marginBottom="42px" display="grid">
            <FormLabel
              marginBottom="6px"
              color="#7D8592"
              fontWeight="600"
              fontSize="14px"
              lineHeight="24px"
              letterSpacing="1px"
            >
              Email address
            </FormLabel>
            <Input
              placeholder="Enter email"
              color="#7D8592"
              fontWeight="600"
              fontSize="14px"
              lineHeight="24px"
              letterSpacing="1px"
              autoComplete="off"
              onChange={(e) => UserNameValidation(e)}
              id="log_mail-inp_adr"
              value={UserName}
            />
            {mailErr && (
              <FormLabel
                marginBottom="0px"
                textAlign="left"
                fontSize="14px"
                color="red"
                fontWeight="400"
                lineHeight="24px"
                marginTop="5px"
                fontFamily="inter"
                marginLeft="3px"
              >
                {errMsg}
              </FormLabel>
            )}
          </FormGroup>
          <FormGroup marginBottom="20px" display="grid">
            <FormLabel
              marginBottom="6px"
              color="#7D8592"
              fontWeight="600"
              fontSize="14px"
              lineHeight="24px"
              letterSpacing="1px"
            >
              Password
            </FormLabel>
            <Input
              type={PasswordShow ? "password" : "text"}
              placeholder="Enter password"
              color="#7D8592"
              fontWeight="600"
              fontSize="14px"
              lineHeight="24px"
              letterSpacing="1px"
              autoComplete="off"
              value={Password}
              onChange={(e) => PasswordValidation(e)}
              id="log_psw-inp_adr"
            />
            {PasswordShow ? (
              <FaRegEye
                style={{
                  position: "absolute",
                  right: 15,
                  top: "43px",
                  cursor: "pointer",
                  color: "#7d8592",
                  fontSize: 20,
                }}
                onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
              />
            ) : (
              <FaRegEyeSlash
                style={{
                  position: "absolute",
                  right: 15,
                  top: "43px",
                  cursor: "pointer",
                  color: "#7d8592",
                  fontSize: 20,
                }}
                onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
              />
            )}
            {misMatch && (
              <FormLabel
                marginBottom="0px"
                textAlign="left"
                fontSize="14px"
                color="red"
                fontWeight="400"
                lineHeight="24px"
                marginTop="5px"
                fontFamily="inter"
                marginLeft="3px"
              >
                {errMsgPass}
              </FormLabel>
            )}
          </FormGroup>
          <FormGroup
            textAlign="right"
            justifyContent="flex-end"
            marginBottom="16px"
            display="flex"
          >
            <LinK
              to="/ForgetPassword"
              fontSize="14px"
              lineheight="18px"
              fontFamily="'Urbanist'"
              onClick={() => dispatch(LoginPass({}))}
            >
              Forgot Password?
            </LinK>
          </FormGroup>
          {accountUpdate === 2 && (
            <Div
              border="1px solid #c3e6cb"
              background="#d4edda"
              padding="0.75rem 1.25rem"
              font="12px 'Manrope',Sans-Serif"
              borderRadius="0.25rem"
              marginBottom="1rem"
            >
              Your provider account is upgraded, Please login again.
            </Div>
          )}
          <FormGroup marginBottom="42px" display="grid">
            <Button
              background="#2C7BE5"
              hoverBackColor="#005FB2"
              className="save_active"
              padding="18px 140px 18px 140px"
              fontWeight="600"
              fontSize="16px"
              lineHeight="22px"
              letterSpacing="1.1px"
              id="log_inp-btn_sbt"
              disabled={btnDisabled}
            >
              Log in
            </Button>
            <Span
              marginTop="32px"
              color="#667085"
              fontSize="18px"
              lineHeight="26px"
              textAlign="center"
            >
              Don't have an account?
              <LinK
                to="/InstantSignIn/New"
                fontSize="18px"
                lineheight="26px"
                fontFamily="'manrope'"
                onClick={() => {
                  dispatch(LoginPass({}));
                  handleMode("1");
                }}
              >
                Sign up
              </LinK>
            </Span>
          </FormGroup>
        </Form>
      </Div>
    </Container>
  );
};

export default LoginRight;
