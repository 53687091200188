import styled from "styled-components";
import { Div, PageSubTitle } from "../../StyledComponents";

export const Container = styled.div`
  overflow-y: visible;
  overflow-x: hidden;
  height: calc(100% - 62px);
  padding-right: 32px;
`;

const setBorder = (index, accept) => {
  if (accept === "0") {
    return "1px solid rgba(219, 219, 219, 1)";
  } else if (accept === "1") {
    if (index === 0) {
      return "none";
    } else if (index === 1) {
      return "1px solid rgba(0, 0, 0, 0.1)";
    } else {
      return "1px solid #dbdbdb";
    }
  } else if (accept === "2") {
    return "1px solid rgba(255, 76, 76, 0.5)";
  }
};

export const CardContainer = styled.div`
  border-radius: 12px;
  border: ${(props) => setBorder(props.index, props.accept)};
  box-shadow: ${(props) =>
    props.index === 1
      ? props.accept === "1"
        ? "0px 15px 8px 6px rgba(229, 229, 229, 0.5)"
        : "none"
      : "none"};
  background: ${(props) =>
    props.index === 0
      ? props.accept === "1"
        ? props.background
        : "none"
      : "none"};
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 32px;
  padding: 16px 0px 12px 0px;
  width: 100%;
  height: max-content;
  position: relative;
`;

export const Div1 = styled.div`
  display: ${(props) => (props.display ? "block" : "none")};
  position: absolute;
  top: 39px;
  right: ${(props) => props.right && props.right};
  /* left: 69%; */
  background: #ffffff;
  z-index: 100;
  box-shadow: 0px 9px 42px rgba(0, 0, 0, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  overflow: auto;
  width: fit-content;
`;
export const DateContainer = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  align-items: center;
  color: ${(p) =>
    p.accept === "2"
      ? "#CED5E0"
      : p.accept === "1"
      ? p.index === 0
        ? "#FFFFFF"
        : "#0066FF"
      : "#0066FF"};
  padding-left: 16px;
`;

export const Options = styled.button`
  margin-left: auto;
  margin-right: 2px;
  border: transparent;
  background: none;
  cursor: pointer;
  color: ${(props) =>
    props.index === 0 && props.accept === 1 ? "#ffffff" : "#CECECE"};

  svg {
    font-size: 22px;
    margin-top: 3px;
  }
  padding-right: 16px;
`;
export const CardBody = styled.ul`
  list-style-type: none;
  color: ${(props) =>
    props.index === 0 && props.accept === "1" ? "#ffffff" : "#2E2E2E"};
  padding: 0px 16px 0px 16px;
`;
export const List = styled.li`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  display: ${(p) => p.display && p.display};
`;
export const BackgroundDiv = styled.div`
  background-color: #2e2e2e;
  box-shadow: ${(props) =>
    props.index === 0 && props.accept === "1"
      ? "0px 16px 24px rgba(0, 0, 0, 0.15)"
      : "none"};
  color: #ffffff;
  width: ${(p) => (p.tag === "button" ? "20px" : "24px")};
  height: ${(p) => (p.tag === "button" ? "20px" : "24px")};
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  margin-top: ${(p) => (p.tag === "button" ? "0px" : "12px")};
  margin-right: ${(p) => (p.tag === "button" ? "0px" : "8px")};
  svg {
    position: relative;
    top: 4px;
  }
`;

export const Address = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) =>
    props.index === 0 && props.accept === "1" ? "#F2F2F2" : "#5B5B5B"};
  margin-top: 2px;
  margin-left: 32px;
  margin-bottom: 8px;
  word-break: break-word;
`;

export const Ptag = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-top: 14px;
`;

export const CheckinButton = styled.button`
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  width: calc(100% - 32px);
  height: 32px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #407bff;
  box-shadow: ${(props) =>
    props.index === 0 ? "0px 16px 24px rgba(0, 0, 0, 0.15)" : "none"};
  border: ${(props) =>
    props.index === 0 ? "1px solid transparent" : "1px solid #CECECE"};
  cursor: pointer;
  margin: 12px 16px 4px 16px;
  align-items: center;
  padding: 8px;
`;

export const Status = styled.h6`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(p) => (p.accept === "2" ? "#FF4D4D" : "#3e76ff")};
  text-align: center;
  ::before {
    content: "";
    border-top: 1px solid #dbdbdb;
    display: block;
    margin-top: 16px;
    margin-bottom: 13px;
  }
`;

export const RespSubTitle = styled(PageSubTitle)`
  @media (max-width: 1100px) {
    font-size: 20px;
  }
`;

export const RespDiv = styled(Div)`
  @media (max-width: 1024px) {
    padding-left: 20px;
  }

  @media (max-width: 980px) {
    padding-left: 18px;
  }
`;
